<template>
<div class="SynchronousPracticeRecords" style="margin: -15px -15px">
      <div class="Synchronous_left">
        <div class="Synchronous_module">模块</div>
        <a-menu style="width: 100%" v-model="selectId" mode="inline">
          <a-menu-item
              v-for="(item, index) in templateList"
              @click="selectStatus(item.id,item.name)"
              :key="item.id"
          >
            {{ item.name }}
          </a-menu-item>
        </a-menu>
        <div style="display: flex;justify-content: right;padding: 5px">
          <a-pagination
              size="small"
              v-model="temPosition.pageNum"
              :total="temPosition.total"
              @change="menuPageChange"/>
        </div>
      </div>
      <div class="Synchronous_right">
        <div class="Synchronous_right_top">
          <div class="Synchronous_right_title">用户每日完成题目数量</div>
          <div>
            <div class="top_cont">
              <div class="EduRec-top-input">
                <span>日期：</span>
                <a-range-picker v-model="datePicker" style="margin-right: 15px;width: 220px" @change="onChange" />
              </div>
              <div class="EduRec-top-input">
                <a-button style="margin-right: 20px" @click="exportModule" >
                  <a-icon style="color: #00A854;" type="file-excel"/>
                  导出该模块
                </a-button>
                <a-button  @click="exportAllModule" >
                  <a-icon style="color: #00A854;" type="file-excel"/>
                  导出全部模块
                </a-button>
              </div>
            </div>
          </div>
          <div class="activity-table">
            <a-table
                @change="orderChange"
                :rowKey="record=>record.id"
                :pagination="false"
                :columns="columns"
                :data-source="data">
          <span slot="location" slot-scope="text, record">
          </span>
            </a-table>
            <!--分页-->
            <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
              <a-pagination v-model="pagination.current"
                            :total="pagination.total"
                            :page-size-options="pageSizeOptions"
                            :page-size.sync="pagination.pageSize"
                            show-size-changer show-quick-jumper
                            @showSizeChange="showSizeChange"
                            @change="pageChange" />
            </div>
          </div>
        </div>

        <div class="Synchronous_right_bottom">
          <div class="Synchronous_right_title">同步练习每日使用人数</div>
          <div>
            <div class="top_cont">
              <div class="EduRec-top-input">
                <span>日期：</span>
                <a-range-picker v-model="datePickerPrevious" style="margin-right: 15px;width: 220px" @change="synchronousOnChange" />
              </div>
              <div class="EduRec-top-input">
                <a-button style="margin-right: 20px" @click="synchronous_exportModule" >
                  <a-icon style="color: #00A854;" type="file-excel"/>
                  导出该模块
                </a-button>
                <a-button  @click="synchronous_exportAllModule" >
                  <a-icon style="color: #00A854;" type="file-excel"/>
                  导出全部模块
                </a-button>
              </div>
            </div>
          </div>
          <div class="activity-table">
<!--            :rowKey="record=>record.id"   -->
            <a-table
                :rowKey="record=>record.id"
                @change="orderSynchronousChange"
                :pagination="false"
                :columns="synchronous_columns"
                :data-source="synchronous_data">
          <span slot="examName" slot-scope="text, record">

            <span v-if="record.examExeType">{{record.examExeType}} -</span> {{record.examName}}

          </span>
            </a-table>
            <!--分页-->
            <div class="pageView">
          <span class="pageLeft">共 {{ synchronous_pagination.total }} 条记录 第 {{
              synchronous_pagination.current
            }} / {{ Math.ceil(synchronous_pagination.total / synchronous_pagination.pageSize) }} 页</span>
              <a-pagination v-model="synchronous_pagination.current"
                            :total="synchronous_pagination.total"
                            :page-size-options="pageSizeOptions"
                            :page-size.sync="synchronous_pagination.pageSize"
                            show-size-changer show-quick-jumper
                            @showSizeChange="synchronous_showSizeChange"
                            @change="synchronous_pageChange" />
            </div>
          </div>
        </div>
      </div>
</div>
</template>

<script>

import {
  getModuleMenu,
  getNumberQuestionsDay,
  getNumberUsersSynchronousExercises,
  NumberQuestionsDayExport, syncExamExport,
} from "@/service/titleExam_api";

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: "6%"
  },{
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    align: 'center',
    ellipsis: true,
    width:'20%'
  },
  {
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    align: 'center',
    width:'8%'
  },
  {
    title: '职称',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    align: 'center',
    ellipsis: true,
    width:'8%'
  },
  {
    title: '日期',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    width: '13%'
  },
  {
    title: '完成题目数量',
    dataIndex: 'questionCount',
    key: 'questionCount',
    align: 'center',
    width: '10%',
    sorter: true
  },
];
const synchronous_columns = [
  {
    title: '练习名称',
    dataIndex: 'examName',
    key: 'examName',
    align: 'center',
    width: "20%",
    scopedSlots: { customRender: 'examName' },
  },
  {
    title: '日期',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    width: '13%'
  },{
    title: '使用人数',
    dataIndex: 'userNumber',
    key: 'userNumber',
    align: 'center',
    width: '10%',
    sorter: true
  },
  {
    title: '完成题目数量',
    dataIndex: 'questionCount',
    key: 'questionCount',
    align: 'center',
    width: '10%',
    sorter: true
  },
];
let data = [];
const synchronous_data = [];
export default {
name: "SynchronousPracticeRecords",
  data(){
  return{
    selectId: [],
    selectTemName:'',
    templateList: [],
    pagination:{
      total:0,
      pageSize: 5,
      current:1,
    },
    spinning:false,
    data,
    synchronous_data,
    columns,
    synchronous_columns,
    startTime:null,
    endTime:null,
    synchronous_pagination:{
      total:0,
      pageSize: 5,
      current:1,
    },
    synchronous_startTime:null,
    synchronous_endTime:null,
    temPosition:{
      pageNum:1,
      pageSize:15,
      total:0,
    },
    order:null,
    orderSynchronous:null,

    datePicker:null,
    datePickerPrevious:null,

    pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
  }
  },
  async created() {
    await this.getLeftTemplate(); //获取左侧菜单列表
    await this.NumberQuestionsDay(); //同步练习-用户每日完成题目数量
    await this.NumberUsersSynchronousExercises(); //同步练习记录-每日使用人数
  },
  methods:{
    async menuPageChange(current, pageSize) {
      this.temPosition.pageNum = current;
      await this.getLeftTemplate();
      await this.NumberQuestionsDay(); //同步练习-用户每日完成题目数量
      await this.NumberUsersSynchronousExercises(); //同步练习记录-每日使用人数
    },
    //获取左侧菜单列表
    async getLeftTemplate() {
      let data = {
        page:this.temPosition.pageNum,
        pageSize:this.temPosition.pageSize
      }
      const res = await getModuleMenu(data);
      if (res.code === 200) {
        this.templateList = res.data;
        this.selectId[0] = res.data[0].id;
        this.selectTemName = res.data[0].name;
        this.temPosition.total = res.count;
      }
    },
    //获取右侧  用户每日完成题目数量 列表
    async NumberQuestionsDay() {
      let data = {
        typeId:this.selectId[0], //模块id
        startTime:this.startTime,
        endTime:this.endTime,
        curPage:this.pagination.current,
        pageSize:this.pagination.pageSize,
        sortType:this.order, //question_up根据问题数量升序，question_down根据问题数量降序
      }
      const res = await getNumberQuestionsDay(data);
      if (res.statuscode === 200) {
        this.data = res.data;
        this.pagination.total = res.count;
      }
    },
    //获取右侧  同步练习每日使用人数 列表
    async NumberUsersSynchronousExercises() {
      let data = {
        typeId:this.selectId[0], //模块id
        startTime:this.synchronous_startTime,
        endTime:this.synchronous_endTime,
        curPage:this.synchronous_pagination.current,
        pageSize:this.synchronous_pagination.pageSize,
        sortType:this.orderSynchronous, //question_down问题数量降序，question_up升序，user_up使用人数升序，user_down降序
      }
      const res = await getNumberUsersSynchronousExercises(data);
      if (res.code === 0) {
        this.synchronous_data = res.data;
        this.synchronous_pagination.total = res.count;
      }
    },
    // 第一个表格排序
    orderChange(page, filters, sorter) {
      //获取排序规则 order --- 升序ascend   降序descend;  key  --  columnKey
      if(sorter.columnKey === 'questionCount'){ // 完成题目数量
        //question_up根据问题数量升序，question_down根据问题数量降序
        if(sorter.order === 'ascend'){ //降序

          this.order = 'question_down';

        }else if(sorter.order === 'descend'){ //降序

          this.order = 'question_up';

        }else {
          this.order = null;
        }
        this.pagination.current = 1;
        this.NumberQuestionsDay();
      }
    },
    // 第二个表格排序
    orderSynchronousChange(page, filters, sorter){
    //获取排序规则 order --- 升序ascend   降序descend;  key  --  columnKey
      //question_down问题数量降序，question_up升序，user_up使用人数升序，user_down降序
      if(sorter.columnKey === 'userNumber'){ //使用人数
        if(sorter.order === 'ascend'){ //升序

          this.orderSynchronous = 'user_down';

        }else if(sorter.order === 'descend'){ //降序

          this.orderSynchronous = 'user_up';

        }else {
          this.orderSynchronous = null;
        }
      }else if(sorter.columnKey === 'questionCount'){ //完成题目数量
        if(sorter.order === 'ascend'){ //升序

          this.orderSynchronous = 'question_down';

        }else if(sorter.order === 'descend'){ //降序

          this.orderSynchronous = 'question_up';

        }else {
          this.orderSynchronous = null;
        }
      }
      this.synchronous_pagination.current = 1;
      this.NumberUsersSynchronousExercises();
    },
    selectStatus(value,name) {
      this.selectId[0] = value;
      this.selectTemName = name;
      this.pagination.current = 1;
      this.pagination.pageSize = 5;

      this.synchronous_pagination.current = 1;
      this.synchronous_pagination.pageSize = 5;

      this.startTime = null;
      this.endTime = null;
      this.Previous_startTime = null;
      this.Previous_endTime = null;
      this.datePicker = null;
      this.datePickerPrevious = null;

      //切换菜单右侧列表切换内容
      this.NumberQuestionsDay();
      this.NumberUsersSynchronousExercises();
    },

    showSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    pageChange(pageNum,pageSize){
      this.pagination.current = pageNum;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pagination.current = 1;
      this.NumberQuestionsDay();
    },
    async exportModule() { //导出-同步练习-用户每日完成题目数量   --- 单个模块
      let ExelName = this.selectTemName;
      let data = {
        startTime: this.startTime,
        endTime: this.endTime,
        typeId:this.selectId[0],
        sortType:this.order,
        ExelName: ExelName
      }
      await NumberQuestionsDayExport(data);
    },
    async exportAllModule() { //导出-同步练习-用户每日完成题目数量  --- 导出全部
      let ExelName = '同步练习使用记录-用户每日完成题目数量';
      let data = {
        ExelName: ExelName,
        sortType:this.order,
      }
      await NumberQuestionsDayExport(data);
    },



    synchronous_showSizeChange(current, pageSize) {
      this.synchronous_pagination.current = current;
      this.synchronous_pagination.pageSize = pageSize;
      this.NumberUsersSynchronousExercises();
    },
    synchronous_pageChange(pageNum,pageSize){
      this.synchronous_pagination.current = pageNum;
      this.synchronous_pagination.pageSize = pageSize;
      this.NumberUsersSynchronousExercises();
    },
    synchronousOnChange(date, dateString) {
      this.synchronous_startTime = dateString[0];
      this.synchronous_endTime = dateString[1];
      this.synchronous_pagination.current = 1;
      this.NumberUsersSynchronousExercises();
    },
    async synchronous_exportModule() {
      let ExelName = this.selectTemName;
      let data = {
        startTime: this.synchronous_startTime,
        endTime: this.synchronous_endTime,
        typeId: this.selectId[0],
        sortType: this.orderSynchronous,
        ExelName: ExelName
      }
      await syncExamExport(data);
    },
    async synchronous_exportAllModule() {
      let ExelName = '同步练习每日使用人数';
      let data = {
        sortType: this.orderSynchronous,
        ExelName: ExelName
      }
      await syncExamExport(data);
    },
  }
}
</script>

<style scoped lang="scss">
.ant-menu{
  border: none;
}
.SynchronousPracticeRecords{
  min-height: 110%;
  display: flex;
  justify-content: space-between;
  .Synchronous_left{
    width: 16%;
    //background-color: #ee7474;
    .Synchronous_module{
      margin-left: 25px;
      margin-top: 10px;
    }

  }
  .Synchronous_right{
    border-left: 15px solid #F0F2F5;
    width: 84%;
    .Synchronous_right_top{
      border-bottom: 15px solid #F0F2F5;
    }
    .Synchronous_right_title{
      width: 98%;
      margin: auto;
      font-weight: bold;
      color: #333333;
      padding-top: 15px;
    }
    .top_cont{
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    .EduRec-top-input{
      height: 50px;
      padding-top: 15px;
    }

    .activity-table{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
    .pageView{
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .pageLeft{
      font-size: 15px;color: #929292
    }
  }
}
</style>
